import { CONSTANTS } from './config';
import HowTo from './modules/howto';
import MenuHandler from './menu';

function init() {
  console.info('@app - init');
  initHowToGuide();
  initMenuHandlers();
}

function initHowToGuide() {
  const howto = document.querySelector(CONSTANTS.howto.dom.base);

  if(!document.body.contains(howto))
    return false;

  new HowTo(howto);
}

function initMenuHandlers() {
  const menuToggleButton = document.querySelector(CONSTANTS.menu.toggleBtn);

  new MenuHandler();
}

document.addEventListener("DOMContentLoaded", init);