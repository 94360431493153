import { CONSTANTS } from "./config";

export default class MenuHandler {
    constructor() {
        this.setupDomReferences();
        this.setupMenuState();
        this.init();
    }

    setupDomReferences() {
        this.body = document.body
        this.toggleButtons = document.querySelectorAll(CONSTANTS.menu.dom.toggleBtns);
    }

    setupMenuState() {
        this.menuIsOpen = false;
    }

    addEventListeners() {
        this.toggleButtons.forEach(elem => {
            elem.addEventListener('click', (e) => {
                this.onToggleClick(e);
            })
        });

        this.body.addEventListener('keyup', (e) => {
            if (e.key == "Escape" && this.menuIsOpen) {
                this.toggleMenu();
            }
        });
    }

    onToggleClick(evt) {
        evt.preventDefault();
        this.toggleMenu();
    }

    toggleMenu() {
        this.menuIsOpen = !this.menuIsOpen;
        this.body.classList.toggle('menu-is-open');
    }

    init() {
        this.addEventListeners();
    }
}